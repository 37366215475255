/* @import css 'react-jinke-music-player/lib/styles/index.less';
@primary-color: #00ccfa; */

:root {
  --spotify-green: #00ccfa;
  --spotify-green-bright: #00ccfa;
  --main-text: #b3b3b3;
  --player-height: 90px;
  --side-bar-margin: 24px;
  --tip-content: #00a2c7;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 13px;
  z-index: 0;
}

/* Track */
::-webkit-scrollbar-track {
  padding: 1px;
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5a5a5a;
  width: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}

body {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: var(--main-text);
  text-transform: none;
  font-family: "Rubik", "Poppins", "Nunito", "Open Sans", "Roboto",
    "Helvetica Neue", Helvetica, Arial, "Hiragino Kaku Gothic Pro", "Meiryo",
    "MS Gothic", sans-serif;
}

.App {
  height: 100vh;
  width: 100vw;
  display: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr;
  overflow: hidden;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #040404;
}

.sidebar {
  background: #040404;
  width: 232px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.logo-link {
  text-decoration: none;
  color: white;
}

.logo {
  padding-left: var(--side-bar-margin);
  padding-right: var(--side-bar-margin);
  margin-bottom: 18px;
}

.spotify-logo {
  width: 131px;
  height: 40px;
}

.nav-list {
  list-style: none;
}

.NavItem {
  padding: 0 8px;
  cursor: pointer;
}

.NavItem:hover > .nav-link {
  color: #fff;
}

.nav-link {
  color: var(--main-text);
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  font-weight: bold;
  transition: all 0.3s;
  border-radius: 4px;
}

.activeMainNav {
  background: #282828;
  color: #fff;
}

.nav-icon {
  margin-right: 16px;
  height: 24px;
}

.playlists {
  margin-top: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 234px;
}

.play-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 0 var(--side-bar-margin) 12px var(--side-bar-margin);
}

.create-button {
  border: none;
  background: transparent;
  padding: 5px var(--side-bar-margin);
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--main-text);
  cursor: pointer;
  transition: all 0.4s;
}

.create-button:hover {
  color: white;
}

.create-button > div > svg {
  background: var(--main-text);
  transition: all 0.4s;
}

.create-button:hover > div > svg {
  background: white;
}

.playlist-icon {
  margin-right: 16px;
}

.featured-label {
  font-weight: 700;
  line-height: 16px;
}

.featured-item {
  transition: all 0.4s;
}

.featured-item-link {
  opacity: 0.7;
  display: flex;
  align-items: center;
  padding: 5px var(--side-bar-margin);
  color: #fff;
  text-decoration: none;
  transition: all 0.4s;
}

.featured-item:hover > .featured-item-link {
  opacity: 1;
}

.list-separator {
  margin: 8px var(--side-bar-margin) 8px var(--side-bar-margin);
  background-color: #282828;
  border: none;
  height: 1px;
}

.other-playlist-container {
  margin: 4px 0;
  overflow: hidden;
  flex: 1;
  min-height: 100px;
  max-height: min-content;
  overflow-y: auto;
  margin-bottom: 80px;
}

.other-list {
  position: relative;
  list-style: none;
  height: 0;
  white-space: nowrap;
}

.side-list {
  margin: 0 var(--side-bar-margin);
}

.list-link {
  color: var(--main-text);
  text-decoration: none;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  cursor: default;
}

.list-link:hover {
  color: #fff;
}

.list-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.cta-wrapper {
  padding: 0 8px;
  background: rgb(0, 0, 0);
}

.cta-link {
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 40px;
  color: var(--main-text);
  text-decoration: none;
}

.nav-icon.install-icon {
  height: 20px;
}

.cta-wrapper:hover {
  color: #fff;
}

.footer {
  min-width: 768px;
  background: #282828;
  border-top: 1px solid #000;
  grid-column: 1/-1;
  position: sticky;
  z-index: 4;
}

.player {
  height: var(--player-height);
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
}

.player-left,
.player-right {
  width: 30%;
  min-width: 180px;
}

.player-left {
  user-select: none;
}

.player-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.extra-controls {
  width: 180px;
  display: flex;
  align-items: center;
}

.volume-control {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 136px;
}

.now-playing {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  transition: transform 0.25s cubic-bezier(0.3, 0, 0, 1);
}

.player-cover {
  width: 56px;
  height: 56px;
  min-width: 56px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin: 0;
  display: block;
  border: 0;
  position: relative;
  z-index: 0;
  background-color: #282828;
}

.player-cover > div {
  position: absolute;
  top: 35%;
  bottom: 35%;
  left: 35%;
  right: 35%;
}

.player-cover > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: 50%;
  background-color: #000;
  background-repeat: no-repeat;
  object-fit: cover;
  object-position: center center;
}

.player-cover > div > svg {
  width: 100%;
  height: 100%;
}

.player-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 14px;
  min-width: 105px;
}

.player-info-track > a {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: #fff;
  text-align: left;
  text-decoration: none;
}

.player-info-artist > a {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.015em;
  text-align: left;
  color: var(--main-text);
  text-decoration: none;
}

.player-info-artist > a:not(:last-child)::after {
  content: ",\00a0";
  display: inline-block;
}

.player-info-track > a:hover,
.player-info-artist > a:hover {
  text-decoration: underline;
}

.player-like-button {
  background-color: transparent;
  border: none;
  color: #b3b3b3;
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-like-button > svg {
  width: 15px;
  height: 15px;
}

.player-like-button:hover {
  color: #fff;
}

.player-center {
  width: 40%;
  max-width: 722px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.player-control-buttons {
  display: flex;
  margin-bottom: 12px;
  width: 224px;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
}

.control-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: #b3b3b3;
  width: 32px;
  min-width: 32px;
  height: 32px;
  position: relative;
}

.control-button:hover {
  color: #fff;
}

.control-button.circle-border {
  border-radius: 500px;
  border: 1px solid hsla(0, 0%, 100%, 0.6);
}

.control-button.circle-border:hover {
  transform: scale(1.07);
  border-color: #fff;
}

.control-button.active {
  color: var(--spotify-green);
}

.control-button.active:hover {
  color: var(--spotify-green-bright);
}

.control-button > svg {
  width: 15px;
  height: 15px;
}

.control-button.smaller > svg {
  width: 12px;
  height: 12px;
}
.control-button.x-smaller > svg {
  width: 11px;
  height: 11px;
}

.control-button.larger > svg {
  width: 16px;
  height: 16px;
}

.control-button.x-larger > svg {
  width: 19px;
  height: 19px;
}

.player-playback {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.connect-devices-wrapper {
  position: relative;
}

/* .connect-devices-wrapper > button {
    position: absolute
} */

.connect-devices {
  position: absolute;
  width: 280px;
  bottom: 40px;
  right: -122px;
  display: none;
  background: #282828;
  border-radius: 5px;
  z-index: 999;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.5);
  display: block;
  animation: fadeIn 333ms cubic-bezier(0.3, 0, 0, 1);
}

.connect-devices::before {
  bottom: -20px;
  left: auto;
  right: 138px;
  margin-left: -10px;
  margin-right: -10px;
  border: 10px solid transparent;
  border-top-color: #282828;
  position: absolute;
  content: "";
}

.connect-devices-content {
  padding: 5px;
  overflow-y: auto;
  max-height: calc(100vh - 90px);
  user-select: none;
}

.connect-devices-title {
  position: relative;
  text-align: center;
  color: #fff;
}

.connect-devices-title > h1 {
  letter-spacing: normal;
  text-transform: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  display: block;
  padding: 14px 14px 10px 14px;
}

.cd-img {
  text-align: center;
  padding: 16px 0;
}

.cd-img > img {
  width: 180px;
}

.connect-devices-list {
  margin: 0 -5px -5px;
}

.connect-devices-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #282828;
  border: 0;
  padding: 10px 15px;
  position: relative;
  width: 100%;
  color: #fff;
  width: 280px;
  height: 57px;
}

.connect-devices-items:not(.disable):hover {
  background-color: #333;
}

.connect-devices-items.active {
  color: var(--spotify-green);
}

.cd-info > h1 {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: normal;
  text-transform: none;
}

.playback-time {
  min-width: 40px;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: normal;
  text-transform: none;
  user-select: none;
}

.progress-wrapper {
  height: 12px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.progress-bar {
  background-color: #535353;
  border-radius: 2px;
  display: flex;
  height: 4px;
  width: 100%;
  overflow: hidden;
}

.progress {
  background-color: #b3b3b3;
  border-radius: 2px;
  height: 4px;
  width: 100%;
  transform: translateX(-100%);
}

.progress.engage {
  background-color: var(--spotify-green);
}

.progress-slider {
  background-color: #fff;
  border: 0;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  z-index: 100;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  opacity: 0;
  position: absolute;
  left: 0;
}

.progress-slider.engage {
  opacity: 1;
}

.control-button.volume:hover + div .progress.volume {
  background-color: var(--spotify-green);
}

.control-button.volume:hover + div .progress-slider.volume {
  opacity: 1;
}

.featured {
  background: #121212;
  overflow-y: scroll;
  position: relative;
}

.status-bar-wrapper {
  position: fixed;
  top: calc(100vh - var(--player-height) - 55px);
  display: flex;
  justify-content: center;
  z-index: 999;
  width: calc(100vw - 232px);
  transition: all 0.5s cubic-bezier(0.3, 0, 0.4, 1);
  opacity: 0;
  visibility: hidden;
}
.status-bar {
  /* left: 50%; */
  background-color: #2e77d0;
  color: #fff;
  border-radius: 8px;
  padding: 12px 36px;
  font-size: 16px;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.5);
  line-height: 20px;
  text-align: center;
  margin: 0;
}

.status-bar-wrapper.active {
  opacity: 1;
  visibility: visible;
}

.header-bar {
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  z-index: 4;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HistoryNav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  margin-right: 16px;
  cursor: not-allowed;
}

.SearchContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.SearchBar {
  position: relative;
  flex: 0 1 364px;
  color: #121212;
}

.SearchInput {
  color: #000;
  padding: 6px 48px;
  height: 40px;
  width: 100%;
  border: 0;
  border-radius: 500px;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.015em;
}

.cNavWrapper {
  white-space: nowrap;
  flex-grow: 1;
  align-items: center;
}

.cNav {
  padding-left: 24px;
  width: 100%;
}

.cNavList {
  text-align: left;
  list-style: none;
  display: flex;
}

.cNavList > li > a {
  margin: 0 8px 0 0;
  color: #fff;
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: normal;
  text-transform: none;
}

.UserPrompt {
  white-space: nowrap;
}

.PromptButton {
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 1.76px;
  text-transform: uppercase;
  border: 2px solid transparent;
  border-radius: 500px;
  color: #fff;
  transition: all 33ms cubic-bezier(0.3, 0, 0, 1);
  white-space: nowrap;
  padding: 8px 34px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

.PromptButton:hover {
  transform: scale(1.05);
}

.UserInfoButton {
  margin-left: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 23px;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer;
  color: #fff;
  padding: 2px;
}

.UserInfoButton:hover {
  background-color: #272727;
}

.UserInfoSpan {
  margin-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 110px;
  pointer-events: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: normal;
  text-transform: none;
}

.UserInfoOptions {
  min-width: 100%;
  background-color: #272727;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.7);
  pointer-events: auto;
  position: absolute;
  top: 100%;
  margin-top: 8px;
  z-index: 1003;
  right: 0;
  list-style: none;
}

.UserInfoOptions > li > * {
  width: 100%;
  padding: 3px 20px;
  line-height: 32px;
  white-space: nowrap;
  background: none;
  border: 0;
  user-select: none;
  cursor: default;
  color: #b3b3b3;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  font-size: 14px;
}

.UserInfoOptions > li > *:hover,
.UserInfoOptions > li > *:focus {
  color: #fff;
  background-color: #333;
}

.UserInfoOptions > :first-child {
  border-radius: 4px 4px 0 0;
}

.UserInfoOptions > :last-child {
  border-top: 1px solid #404040;
  border-radius: 0 0 4px 4px;
}

.page-content {
  padding: 0 32px 85px 32px;
  max-width: 1955px;
  z-index: 3;
}

.pageContent {
  padding: 16px 0;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-gap: 32px;
}

.CollectionRow {
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.RowTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.seeAll {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-decoration: none;
  color: #b3b3b3;
}

.seeAll:hover {
  text-decoration: underline;
}

.RowGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
  grid-template-rows: 1fr;
  overflow-y: hidden;
  overflow-x: hidden;
  grid-gap: 16px;
  z-index: 3;
}

.PlayCard {
  background: #272727;
  padding: 20px 20px 16px;
  border-radius: 8px;
  width: 100%;
  height: min-content;
  cursor: pointer;
  z-index: 3;
  position: relative;
}

.smallButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--spotify-green);
  color: #fff;
  border: 0;
  border-radius: 50%;
  margin: 0;
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 0;
  right: 0;
  margin-bottom: 16px;
  margin-right: 20px;
  z-index: 3;
  display: none;
}

.smallButton:hover {
  transform: scale(1.06);
}

.smallButton:active {
  transform: scale(1);
}

.pcWrapper {
  position: relative;
  user-select: none;
}

.pcWrapper:hover .smallButton {
  display: flex;
}

.CardDisplay {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 16px;
  background-color: transparent;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.previewImg {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.CardInfo {
  height: 64px;
}

.CTA-banner {
  display: flex;
  cursor: pointer;
  background-image: linear-gradient(90deg, #af2896, #509bf5);
  width: 100%;
  padding: 11px 24px 7px 15px;
  align-items: center;
  justify-content: space-between;
}

.cta-content {
  margin-left: 24px;
  color: #fff;
}

.browsePage {
  color: #fff;
  margin-top: 16px;
  padding: 16px 0;
}

.browseTitle {
  margin-bottom: 16px;
}

.browseGrid {
  margin: 16px 0;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
  grid-auto-rows: auto;
}

.browseLinkContainer {
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 8px;
}

.browseLinkContainer::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.browseLink {
  position: absolute;
  width: 100%;
  color: #fff;
  text-decoration: none;
}

.GenrePage {
  padding-top: 16px;
}

.listPage {
  margin-top: -60px;
  padding-bottom: 32px;
}

.banner {
  max-height: 500px;
  min-height: 340px;
  height: 30vh;
  color: #fff;
  position: relative;
  padding: 0 32px 24px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
}

.bannerOverlay {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
}

.bannerImgDiv {
  margin-right: 24px;
  width: auto;
  height: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  min-width: 192px;
  background-color: #282828;
  color: #7f7f7f;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.5);
}

.bannerImg {
  width: 100%;
  /* min-width: 192px; */
  height: 100%;
  /* object-fit: cover; */
  /* object-position: center center; */
  z-index: 3;
}

.circleDiv {
  border-radius: 50%;
}

.bannerInfo {
  z-index: 1;
  display: flex;
  flex-flow: column;
}

.pageTitle {
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 4px;
  font-size: 12px;
  text-transform: uppercase;
}

.bannerTitle {
  padding: 0.08em 0px;
  font-size: 48px;
  line-height: 48px;
  font-weight: 900;
  letter-spacing: -0.04em;
}

.bannerTitleXL {
  padding: 0.08em 0px;
  font-size: 48px;
  line-height: 60px;
  font-weight: 900;
  letter-spacing: -0.04em;
}

.bannerDescription {
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.additionalInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
}

.additionalInfo > a {
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  text-transform: capitalize;
}

.additionalInfo > a:hover {
  text-decoration: underline;
}

.additionalInfo > h2 {
  color: hsla(0, 0%, 100%, 0.7);
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}

.additionalInfo :not(:first-child)::before {
  content: "•";
  margin: 0 4px;
  display: inline-block;
}

.playListContent {
  position: relative;
}

.playListOverlay {
  height: 232px;
  width: 100%;
  position: absolute;
  z-index: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), #121212);
}

.playListFunctions {
  display: flex;
  position: relative;
  padding: 24px 32px;
  width: 100%;
  align-items: center;
  z-index: 1;
}

.playListFunctions :not(:last-child) {
  margin-right: 24px;
}

.playListFunctions > :first-child {
  margin-right: 32px;
}

.followButton {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: transparent;
  padding: 7px 15px;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
}

.followButton:hover {
  border-color: #fff;
}

.playButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background: var(--spotify-green);
  color: #fff;
  border: 0;
  border-radius: 50%;
  margin-right: 32px;
}

.playButton:hover {
  transform: scale(1.05);
}

.likeButton {
  width: 32px;
  height: 32px;
  background: transparent;
  border: 0;
  color: hsla(0, 0%, 100%, 0.7);
}

.likeButton:not(.noHover):hover {
  color: #fff;
}

.moreButton {
  width: 32px;
  height: 20px;
  background: transparent;
  border: 0;
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 16px;
}

.moreButton:hover {
  color: #fff;
}

.trackListContainer {
  position: relative;
}

.trackList {
  width: 100%;
  /* margin-bottom: 1.2em; */
}

.trackListItem {
  height: 5.4em;
  position: relative;
  transition: background-color 0.2s linear;
  list-style-type: none;
  user-select: none;
  display: flex;
  border-radius: 5px;
  padding: 5px 10px 5px 0px;
}

.trackListItem:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.highlight {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.trackItemPlay {
  box-sizing: border-box;
  position: relative;
  text-align: right;
  padding-right: 1em;
  width: 42px;
}

.trackItemThumb {
  background-color: #282828;
  color: #fff;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.trackItemThumb > div > svg {
  width: 100%;
  height: 100%;
}

.trackMidAlign {
  height: 100%;
  display: flex;
  align-items: center;
}

.itemIcon {
  padding-top: 3px;
  color: #fff;
  opacity: 0.6;
}

.hoverIcon {
  display: none;
  color: #fff;
  z-index: 3;
  background: none;
  border: none;
  margin: 0;
}

.trackTopAlign {
  margin-top: 0.75em;
}

.trackListItem:hover .itemIcon {
  display: none;
}

.trackListItem:hover .hoverIcon {
  display: inline-block;
}

.trackItemInfo {
  flex: 1 1 0;
  overflow: hidden;
}

.ellipsis-one-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.trackName {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #fff;
  text-transform: capitalize;
}

.trackInfo {
  display: flex;
  align-items: center;
  position: relative;
}

.explicit-label {
  margin-right: 0.67em;
  margin-left: 0.1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.6);
  color: #121212;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 10px;
  min-width: 16px;
  height: 16px;
  padding: 0 2px;
}

.trackArtists > a {
  color: #fff;
  opacity: 0.6;
  transition: opacity 0.2s linear;
  text-decoration: none;
}

.trackArtists > a:hover {
  opacity: 1;
  text-decoration: underline;
}

.trackArtists > a:not(:last-child)::after {
  content: ",\00a0";
  display: inline-block;
}

.trackInfoSep {
  color: #fff;
  opacity: 0.6;
  display: inline-block;
  transform: translateY(-1px);
  font-size: 0.8em;
  padding: 0 8px;
}

.trackAlbum > a {
  color: #fff;
  opacity: 0.6;
  transition: opacity 0.2s linear;
  text-decoration: none;
}

.trackAlbum > a:hover {
  opacity: 1;
  text-decoration: underline;
}

.trackItemDuration {
  text-align: right;
  padding-right: 1em;
  font-feature-settings: "tnum";
  letter-spacing: 0;
  width: 3.9em;
}

.duration {
  color: #fff;
  opacity: 0.6;
}

.no-outline:focus {
  outline: none;
}

.svgSizing > svg {
  width: 48px;
  height: 48px;
}

.menuNavList {
  text-align: left;
  list-style: none;
}

.AboutNavItem {
  display: inline-block;
}

.aboutLink {
  margin: 0 8px 0 0;
  color: #fff;
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
}

.aboutLink-active {
  background-color: #333;
}

.ArtistRowGrid {
  display: grid;
  grid-gap: 35px 16px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: 1fr;
}

.artistRowThumb {
  background-color: #282828;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.artistRowThumb > div {
  color: #fff;
  position: absolute;
  top: 35%;
  bottom: 35%;
  left: 35%;
  right: 35%;
}

.artistRowThumb > div > svg {
  width: 100%;
  height: 100%;
}

.artistRowThumb::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.trackItemThumb > div > svg {
  width: 100%;
  height: 100%;
}

.artistRowName {
  margin: 12px 0 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: block;
}

.artistRowName > a {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.015em;
  font-weight: 700;
  color: #fff;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
}

.toolTip {
  opacity: 1 !important;
  padding: 16px !important;
  text-align: start !important;
  border-radius: 8px !important;
  width: 350px !important;
  min-width: 300px !important;
  margin-left: -30px !important;
}

.ttMain {
  margin: 0 !important;
}

.artistRowName > a:hover {
  text-decoration: underline;
}

.tipContent {
  width: 100%;
}

.tipContent > h2 {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: normal;
  text-transform: none;
  margin-bottom: 8px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.tipContent > h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: normal;
  text-transform: none;
  white-space: pre-wrap;
}

.tipOptions {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin-top: 24px;
}

@media (min-width: 1024px) {
  .bannerTitle {
    font-size: 72px;
    line-height: 72px;
  }
}

@media (min-width: 1280px) {
  .bannerImgDiv {
    width: 232px;
    height: 232px;
  }

  .bannerTitle {
    font-size: 96px;
    line-height: 96px;
  }

  .svgSizing > svg {
    width: 64px;
    height: 64px;
  }
}

@media (min-width: 1710px) {
  .bannerTitleXL {
    font-size: 72px;
    line-height: 72px;
  }
}

@media (max-width: 1023px) {
  .mediaNone {
    display: none;
  }

  .header-bar {
    padding: 16px;
  }
}

@media (max-width: 1279px) {
  .mediaNoneXL {
    display: none;
  }
}

.dropdown-item:active {
  background: rgba(255, 255, 255, 0.1);
}
.dropdown-menu {
  background-color: #282828;
}
.dropdown-item {
  color: #fff;
  padding: 6px 20px;
}
@media only screen and (max-width: 992px) {
  .dropdown-item {
    padding: 4px 12px;
  }
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}
.playlist_dropdown {
  border: none !important;
  padding: 0;
  background-color: transparent !important;
}
.playlist_dropdown::after {
  display: none;
}
.playlist_dropdown:hover,
.playlist_dropdown:active,
.playlist_dropdown:focus,
.playlist_dropdown:focus-visible {
  background-color: transparent !important;
  border: none !important;
  box-shadow: unset !important;
}

a {
  text-decoration: none;
  color: transparent;
}

a:hover {
  color: white;
  text-decoration: none;
}

.user_dropdown .dropdown-toggle::after {
  display: none;
}

.modify_btn {
  background-color: transparent;
  font-size: 14px;
  line-height: 1;
  border-radius: 500px;
  padding: 16px 48px 18px;
  transition-duration: 0.3s;
  border-width: 0;
  letter-spacing: 2px;
  min-width: 160px;
  text-transform: uppercase;
  white-space: normal;
  font-weight: 700;
}

.secondary-btn {
  /* color: #616467;   */
  color: whitesmoke;
  box-shadow: inset 0 0 0 2px #616467;
}

.secondary-btn:hover {
  color: #fff;
  background-color: #616467;
  border-color: #adadad;
  text-decoration: none;
  outline: 0;
}

.secondary-btn:focus {
  color: #fff;
  background-color: #616467;
  border-color: #adadad;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.secondary-btn:active {
  color: #fff;
  background-color: #616467;
  border-color: #adadad;
  text-decoration: none;
  outline: 0;
}

.fb-btn {
  color: #fff;
  background-color: #3b5998;
}

.fb-btn:hover {
  color: #fff;
  background-color: #3a61b3;
  text-decoration: none;
  outline: 0;
}

.input_label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  /* color: #222326; */
  color: whitesmoke;
}

.form-control:focus {
  border-color: #919496;
  outline: 0;
  box-shadow: none;
}

.form-control {
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9dadc;
  border-radius: 0;
}

.primary-btn {
  color: #fff;
  background-color: #00ccfa;
}

.primary-btn:hover {
  color: #fff;
  background-color: #00ccfa;
}

.primary-btn:active {
  color: #fff;
  background-color: #00ccfa;
}

.primary-btn:focus {
  color: #fff;
  background-color: #00ccfa;
  border: none;
}

.text_center {
  text-align: center;
}

.form-control:invalid {
  background-image: none !important;
}

.form-control:valid {
  background-image: none !important;
}

.custom_button {
  background-color: var(--spotify-green);
  border-color: var(--spotify-green);
  color: #ffffff;
  padding: 10px 12px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
}

.custom_button:hover {
  color: #ffffff;
}

.custom_file_upload .form-file-label {
  margin-bottom: 0rem !important;
}
.custom_file_upload .form-control-file {
  display: none !important;
}

.bg-btn {
  background-color: var(--spotify-green) !important;
}

.bg-btn:hover {
  background-color: var(--spotify-green) !important;
  border: none;
}

.bg-btn:focus {
  background-color: var(--spotify-green) !important;
  border: none;
}

.bg-btn:active {
  background-color: var(--spotify-green) !important;
  border: none;
}

/* player */
.custom-player
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn {
  display: none;
}

.custom-player .music-player-panel .panel-content .rc-slider-handle,
.custom-player .music-player-panel .panel-content .rc-slider-track {
  background-color: var(--spotify-green);
}

.custom-player svg:active,
.custom-player svg:hover {
  color: var(--spotify-green);
}

.custom-player .music-player-panel {
  background-color: rgba(0, 0, 0, 0.3);
}

.custom-player
  .music-player-panel
  .panel-content
  .player-content
  .play-btn
  svg {
  font-size: 43px;
}
